// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-download-jsx": () => import("/opt/build/repo/src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-help-jsx": () => import("/opt/build/repo/src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("/opt/build/repo/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */)
}

